import React, {
  useRef,
  useEffect,
  useContext
} from "react";

//Components
import Alertbar from "src/components/Layout/Alertbar";
import { EMSHero } from "./hero/EMSHero";

//Hooks & Helpers
import useHashValue from "src/components/helpers/useHashValue";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";

import getOGMarkup from "src/components/helpers/getOGMarkup";
import useEmsHomePage from "src/hooks/useEmsHomePage";
import getContentConnectorFields from "src/components/helpers/getContentConnectorFields";

import getSchemaMarkup from "../helpers/getSchemaMarkup";
import { AccruentImg, LocalizedLink, SEO, ContentConnector} from "../commonComponents";
import { getPageDataJsonPath, getImageSrcFromGatsbyImg } from "src/utils";

const getImageObject = require("src/../helpers/getImageObject");
const getMetaData = require("src/../helpers/getMetaData");


const getSolutionData = (data) => {
  const ProductLogo = getImageObject(data?.relationships?.fieldSolutionItems?.[0]?.relationships?.fieldProductLogo,data?.relationships?.fieldSolutionItems?.[0]?.fieldProductLogo)
  const Graphics = getImageObject(data?.relationships?.fieldGraphic,data?.fieldGraphic)
  let parsedSolution = {
     name: data?.fieldOverrideItem ? data?.fieldTitle : data?.relationships?.fieldSolutionItems?.[0]?.fieldProductName,
     description: data?.fieldOverrideItem ? data?.fieldBody :data?.relationships?.fieldSolutionItems?.[0]?.fieldProductDescription_,
     image: data?.fieldOverrideItem ? Graphics :ProductLogo,
     path: data?.fieldOverrideItem ? data?.fieldCtaUrl?.url :data?.relationships?.fieldSolutionItems?.[0]?.entityUrl?.path,
     linkText: data?.fieldOverrideItem ? data?.fieldCtaUrl?.title : ""
   }
   return parsedSolution;
 }
const EmsIndexPage = ({ data, location, path }) => {

  const { hash } = useHashValue(location);
  const scrollRef = useRef();
  // const eventRef = useRef();
  const { alertBarData } = useContext(ResourcesContext);
  const { emsData } = useEmsHomePage();
  let parsedEmsData = emsData

  useEffect(() => {
    if (hash && scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [hash]);

  const solutionData = parsedEmsData?.relationships?.fieldSolutionSection
  const OGMarkup = getOGMarkup('EMS','homepage');


  let contentConnectors = [];
  if (Array.isArray(parsedEmsData?.relationships?.fieldContentSections)) {
    contentConnectors = getContentConnectorFields(
      parsedEmsData?.relationships?.fieldContentSections,
      "ems homepage",
      'en'
    );
  }

  const schemaMarkup = getSchemaMarkup(parsedEmsData?.fieldSchemaMarkup)
  const entityMetatags = getMetaData(parsedEmsData?.metatag)
  if (!entityMetatags?.find((meta) => (meta?.name === 'description'))) {
    const desc = parsedEmsData?.relationships?.fieldHeroSection?.[0]?.fieldHeroDescription
    entityMetatags.push({ name: 'description', content: desc })
  }
  const pageDataPath = getPageDataJsonPath('/index')
  const heroSection = parsedEmsData?.relationships?.fieldHeroSection;

  const preloadImages = [
    getImageSrcFromGatsbyImg(
      heroSection?.[0]?.relationships?.fieldBackgroundImage?.relationships
        ?.fieldImage
    ),
    getImageSrcFromGatsbyImg(
      heroSection?.[0]?.relationships?.fieldHeroVideo2?.relationships?.thumbnail
    ),
  ];

  return (
    <div className="ems-homepage">
      <SEO
        alternatives={[
          {
            href: "",
            hreflang: "en"
          },
          {
            href: "",
            hreflang: "x-default"
          }
        ]}
        OGMarkup={OGMarkup}
        schemaMarkup={schemaMarkup}
        meta={entityMetatags}
        preloads= {[pageDataPath]}  
        preloadedImages={preloadImages}
      />
      <EMSHero heroEmsData={heroSection} banner={parsedEmsData?.relationships?.fieldBannerSection}/>
      <div className="homepage-content-wrapper tw-pt-[44px] tw-pb-8 lg:!tw-pb-[76px] md:tw-ml-4 md:tw-mr-4 lg:tw-ml-0 lg:tw-mr-0">
        {!!alertBarData && <Alertbar />}
        {solutionData && solutionData.length > 0 && (
          <section className="choose-your-product">
            <div className="tw-container_custom !tw-mt-14">
              <div className="columns tw-flex tw-columns">
                <div className="tw-column tw-w-full md:tw-w-9/12 md:tw-flex-none">
                <h2 className="title title-3">
                    {parsedEmsData?.fieldSubtitle}
                </h2>
                </div>
              </div>
              <div className="columns tw-flex tw-columns is-mobile tw-justify-center is-multiline tw-justify-stretch industry-cards text-accruent_gray1 tw-text-accruent_gray1">
                {solutionData.map((solution) => {
                  const parsedSolution = getSolutionData(solution);
                  const image = parsedSolution?.image?.gatsbyImage?parsedSolution?.image:null
                  return (
                    <div
                      className="tw-column tw-w-full md:tw-w-1/2 lg:tw-w-1/3 tw-mt-2"
                      key={solution.uuid}
                    >
                      <LocalizedLink to={parsedSolution?.path} className="tw-font-bold tw-text-link-color">
                        <div className="card homepage-product-card tw-justify-start !tw-rounded-t-[48px] !tw-rounded-br-[48px] tw-flex tw-flex-col !tw-h-full !tw-border-0 tw-shadow-[0_2px_12px_0_rgba(125,125,125,0.16)]">
                          <div className="card-image card-main-image">
                            <figure className="image">
                            {image?.gatsbyImage && <AccruentImg file={image} className={image? "resource-card-image" : 'card-local-image'} loading="eager" width={720} />}
                            </figure>
                          </div>
                          <div className="card-content tw-text-accruent_sapphire !tw-py-7 !tw-px-3">
                            <h6 className="title-6 tw-pt-2.5 !tw-mb-4">
                              {parsedSolution?.name}
                            </h6>
                            <div className="card-text tw-min-h-[60px] tw-text-base tw-text-accruent_gray1 tw-leading-[21px] tw-font-normal tw-mb-10 tw-font-Roboto">
                            <p className="tw-text-accruent_black">
                              <div dangerouslySetInnerHTML={{
                              __html: parsedSolution?.description
                              }}/>
                            </p>
                            </div>
                            <div className="align-btn tw-absolute tw-bottom-[26px]">
                            {parsedSolution?.linkText ? parsedSolution?.linkText :"Learn More  ›"}
                            </div>
                          </div>
                        </div>
                      </LocalizedLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        )}
        {contentConnectors.map((data, index) => (
          <div key={index} className="tw-container_custom !tw-mt-14">
            <ContentConnector
              key={index}
              data={data}
              pageType={parsedEmsData?.type?.id}
              locale={'en'}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmsIndexPage;
